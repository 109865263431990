.mobile-categories {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px 25px;
    /*z-index: 0;*/
    #mobile_categories {
        display: block;
        width: 100%;
        margin: 0 auto;
        max-width: 700px;
        height: 35px;
        font-weight: bold;
        font-size: 16px;
    }
    @media screen and (min-width: 992px) {
        display: none;
    }
}

.lb-number,
.lb-nav {
    display: none !important;
}

@-moz-keyframes bounce_up {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(5px);
        transform: translateY(5px);
    }
    60% {
        -moz-transform: translateY(2.5px);
        transform: translateY(2.5px);
    }
}

@-webkit-keyframes bounce_up {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    60% {
        -webkit-transform: translateY(2.5px);
        transform: translateY(2.5px);
    }
}

@keyframes bounce_up {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
    60% {
        -moz-transform: translateY(2.5px);
        -ms-transform: translateY(2.5px);
        -webkit-transform: translateY(2.5px);
        transform: translateY(2.5px);
    }
}

@-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

.bounce-up {
    -moz-animation: bounce_up 2s infinite;
    -webkit-animation: bounce_up 2s infinite;
    animation: bounce_up 2s infinite;
}

.order-wrapper {
    position: relative;
    z-index: 1;
    .store-info {
        background-color: $background_1_fallback;
        background-color: $background_1;
        box-shadow: 0px 2px 3px 1px $general_shadow;
        margin-top: 25px;
        .store-inner {
            padding: 15px;
            display: block;
            .store-image {
                position: relative;
                display: block;
                height: 120px;
                width: 100%;
                cursor: pointer;
                background-size: cover;
                background-position: center;
                &:before {
                    cursor: pointer;
                    content: "\f00e";
                    font-family: FontAwesome;
                    font-size: 2em;
                    color: $white;
                    text-align: center;
                    line-height: 200px;
                    z-index: 2;
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    transition: 200ms all ease-in;
                }
                &:after {
                    cursor: pointer;
                    content: '';
                    z-index: 1;
                    display: block;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0);
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    transition: 100ms all ease-in;
                }
                &:hover {
                    &:before {
                        opacity: 1;
                        line-height: 120px;
                    }
                    &:after {
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
            .info-rating {
                background-color: $background_2_fallback;
                background-color: $background_2;
                display: block;
                text-align: center;
                position: relative;
                .rating-link {
                    padding: 5px 10px;
                    display: block;
                    color: $text_default;
                    cursor: pointer;
                    position: relative;
                    &:active,
                    &:focus,
                    &:link,
                    &:hover {
                        color: $text_default;
                        text-decoration: none;
                    }
                    .rating-star {
                        color: $orange;
                        display: block;
                        clear: both;
                        font-weight: bold;
                    }
                    .rating-text {
                        display: block;
                        clear: both;
                        /*font-weight: lighter;*/
                    }
                    &:before {
                        cursor: pointer;
                        content: "\f14c";
                        font-family: FontAwesome;
                        font-size: 1.5em;
                        color: $white;
                        text-align: center;
                        line-height: 80px;
                        z-index: 2;
                        display: block;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        transition: 200ms all ease-in;
                    }
                    &:after {
                        cursor: pointer;
                        content: '';
                        z-index: 1;
                        display: block;
                        height: 100%;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0);
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        transition: 100ms all ease-in;
                    }
                    &:hover {
                        &:before {
                            opacity: 1;
                            line-height: 50px;
                        }
                        &:after {
                            background-color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }
            .info-list {
                padding: 0;
                margin: 0;
                list-style-type: none;
                color: $text_default;
                li {
                    padding-top: 2.5px;
                    padding-bottom: 2.5px;
                    color: $text_default;
                    /*background-color: $choise_background_1_color;*/
                }
                p {
                    margin: 0;
                    color: $text_default;
                    /*background-color: $choise_background_1_color;*/
                }
                .info-title {
                    margin: 0;
                    font-weight: bold;
                    color: $text_default;
                    /*background-color: $choise_background_1_color;*/
                    /*font-size: 22px;*/
                    margin-bottom: 10px;
                    @media screen and (max-width: 767px) {
                        font-size: 20px;
                        margin-top: 15px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .block-layout {
        .prod-grid {
            width: 100%;
            transition: 100ms width ease-in;
        }
        .hide-this-topping {
            position: absolute;
            right: 15px;
            top: 15px;
            background-color: $accent_1;
            color: $accent_1_color;
            z-index: 1;
        }
        &.opencart {
            .prod-grid {
                /*width: 100%;*/
                width: 70%;
                ul.categorie-container {
                    >li {
                        flex-basis: 33.332%;
                    }
                }
            }
            @media screen and (max-width: 1600px) {
                .prod-grid {
                    width: 100%;
                }
            }
        }
        .product-tab {
            display: none;
            flex-wrap: wrap;
            &:first-child {
                display: flex;
            }
        }
        .categorie-container {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            list-style-type: none;
            li {
                padding: 15px;
                display: block;
                flex-basis: 12.5%;
                flex-basis: 25%;
                .categorie-item {
                    cursor: pointer;
                    width: 100%;
                    display: block;
                    padding: 0;
                    border-width: 0;
                    background-color: $background_1_fallback;
                    background-color: $background_1;
                    transform: scale(1);
                    transition: 100ms all ease-in;
                    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
                    .categorie-cover {
                        height: 150px;
                        width: 100%;
                        display: block;
                        background-size: cover;
                        background-position: center;
                    }
                    &:after {
                        content: '';
                        display: block;
                        height: 100%;
                        width: 100%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-color: rgba(0, 0, 0, 0.5);
                        position: absolute;
                        transition: 150ms all ease-in;
                    }
                    .button-angle-down {
                        opacity: 0;
                        position: absolute;
                        bottom: 10px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        text-align: center;
                        font-size: 3em;
                        color: #fff;
                        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.9);
                    }
                    .categorie-title {
                        position: absolute;
                        max-width: 100%;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: 150ms all ease-in;
                        z-index: 1;
                        height: auto;
                        width: auto;
                        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0);
                        top: 0px;
                        left: 0px;
                        padding: 10px 15px;
                        font-size: 20px;
                        font-weight: lighter;
                        background-color: transparent !important;
                        &.categorie-title-label {
                            top: 5px;
                            left: -15px;
                            background-color: $accent_1;
                            padding: 5px 10px;
                            top: 10px;
                            left: -5px;
                        }
                    }
                    &:hover,
                    &:focus {
                        transform: scale(1.05);
                        box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.15);
                        &:after {
                            background-color: rgba(0, 0, 0, 0);
                        }
                        .button-angle-down {
                            opacity: 1;
                        }
                        .categorie-title {
                            opacity: 0;
                            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0);
                            color: #fff;
                            background-color: rgba(0, 0, 0, 0);
                            /*font-weight: normal;*/
                            &.categorie-title-label {
                                color: #fff;
                                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0);
                            }
                        }
                    }
                    &.active {
                        /*transform: scale(1.05);*/
                        /*box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.15);*/
                        .categorie-title {
                            /*border: solid 5px $accent_1_fallback;*/
                            /*border: solid 5px $accent_1;*/
                            /*color: $accent_1_color;*/
                            /*background-color: transparent;*/
                            /*color: transparent;*/
                            /*text-shadow: none;*/
                        }
                        .categorie-title {
                            /*color: #fff;*/
                            /*background-color: rgba(0, 0, 0, 0);*/
                        }
                        &:after {
                            /*background-color: rgba(0, 0, 0, 0);*/
                        }
                        &:hover {
                            /*transform: scale(1);*/
                            /*box-shadow: none;*/
                            .categorie-title {
                                /*text-shadow: none;*/
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 1500px) {
                li {
                    flex-basis: 20%;
                }
            }
            @media screen and (max-width: 1299px) {
                width: 100%;
                max-width: 100%;
                overflow-x: auto;
                /*flex-wrap: nowrap;*/
                /*white-space: nowrap;*/
                /*display: flex;*/
                li {
                    width: 175px;
                    display: inline;
                    position: relative;
                    flex-basis: 175px;
                    max-width: none;
                    /*min-width: 22.5%;*/
                    width: 100%;
                    justify-content: center;
                }
            }
            /*@media screen and (max-width: 899px) {
                li {
                    min-width: 29%;
                }
            }

            @media screen and (max-width: 599px) {
                li {
                    min-width: 60%;
                }
            }*/
        }
        .inner {
            height: 100%;
            .block-prod-container {
                padding-bottom: 30px;
                height: 100%;
                .block-product {
                    background-color: $background_1_fallback;
                    background-color: $background_1;
                    background-color: $background_product;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    position: relative;
                    height: 100%;
                    .cover-img {
                        /*height: 200px;*/
                        width: 100%;
                        background-size: cover;
                        background-position: center;
                        display: block;
                        position: relative;
                        .block-product-title {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background-color: rgba(0, 0, 0, 0.75);
                            color: $white;
                            margin: 0;
                            padding: 7.5px 10px;
                            font-size: 1.25;
                            font-weight: lighter;
                        }
                        &.prod-gird-background-cover {
                            display: block;
                            background-size: cover;
                            height: 220px;
                            background-repeat: no-repeat;
                            width: 100%;
                            background-position: center center;
                        }
                    }
                    .product-info {
                        padding: 15px;
                        .product-description {
                            font-style: italic;
                            @media screen and (min-width: 992px) {
                                /*min-height: 65px;*/
                                /*max-height: 65px;*/
                                /*overflow-y: auto;*/
                            }
                        }
                    }
                    .block-product-cta {
                        display: block;
                        background-color: $green;
                        background: linear-gradient(to left, $green 50%, $green_darken 50%);
                        background-size: 200% 100%;
                        background-position: right bottom;
                        color: #fff;
                        cursor: pointer;
                        transition: 250ms all ease-in;
                        width: 100%;
                        border-width: 0;
                        text-align: left;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        right: 0;
                        &:hover,
                        &:focus {
                            background-position: left bottom;
                        }
                        .price {
                            margin: 0;
                            padding: 0;
                            padding: 10px;
                            width: 80%;
                            display: block;
                            &:hover {
                                text-decoration: none;
                            }
                            .price_old {
                                text-decoration: line-through;
                            }
                            .price_word {
                                font-size: 10px;
                                padding-left: 5px;
                                padding-right: 5px;
                                &+.price_new {
                                    font-weight: bolder;
                                }
                            }
                        }
                        .add {
                            font-weight: bolder;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            width: 20%;
                            text-align: center;
                            font-size: 2em;
                            background-color: rgba(0, 0, 0, 0.15);
                            line-height: 39px;
                        }
                    }
                }
            }
        }
    }
    .prod-grid {
        &.relative-layout {
            position: relative;
            .product-tab-container {
                position: relative;
                .product-tab>div {
                    position: inherit;
                    .toppings {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        z-index: 1;
                        background-color: $background_1;
                        margin-top: 0;
                        padding-top: 15px;
                    }
                }
                .product-tab {
                    display: none;
                }
                &:first-child {
                    .product-tab {
                        /*Let the first tab display*/
                        /*display: flex;*/
                    }
                }
            }
        }
    }
    .allergie {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        li {
            img {
                width: 30px;
                height: 30px;
                padding: 2.5px;
            }
        }
    }
    .list-layout {
        @media screen and (min-width: 992px) {
            padding-bottom: 90vh;
        }
        display: block;
        margin-top: 25px;
        margin-bottom: 25px;

        .nav-categories {
            display: block;
            padding-top: 25px;
            display: block;
            padding-top: 25px;
            .list-layout-title {
                margin: 0;
            }
            .nav-categories-list {
                display: block;
                padding: 0;
                margin: 0;
                list-style-type: none;
                padding-top: 16px;
                max-height: 80vh;
                overflow-y: auto;
                min-height: 79vh;
                @media screen and (max-height: 600px) {
                    max-height: 60vh;
                    min-height: 59vh;
                }
                @media screen and (max-height: 330px) {
                    max-height: 40vh;
                    min-height: 39vh;
                }
                li {
                    position: relative;
                    a {
                        padding-left: 15px;
                        display: block;
                        padding-bottom: 4px;
                        padding-top: 4px;
                        text-decoration: none;
                        color: $grey;
                        &:hover,
                        &:focus,
                        &:active {
                            color: $grey;
                            text-decoration: none;
                        }
                    }
                    &:hover {
                        a {
                            font-weight: bolder;
                            color: $accent_1_fallback;
                            color: $accent_1;
                        }
                        &:before {
                            left: 5px;
                        }
                    }
                    &:before {
                        content: '\203A';
                        color: $darkgrey;
                        font-weight: 700;
                        transition: 100ms all ease-in;
                        position: absolute;
                        left: 0px;
                        line-height: 27px;
                    }
                    &.active {
                        a {
                            font-weight: bolder;
                            color: $accent_1_fallback;
                            color: $accent_1;
                        }
                        &:before {
                            left: 5px;
                        }
                    }
                }
            }
        }
        .product-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            .list-item {
                background-color: $background_1_fallback;
                background-color: $background_1;
                background-color: $background_product;
                margin: 7.5px 0px;
                display: block;
                position: relative;
                &.no-image {
                    height: auto !important;
                    padding: 0px !important;
                    .categorie-title-container {
                        position: relative !important;
                    }
                }
                &.list-header {
                    padding: 10px;
                    height: 150px;
                    margin-top: 20px;
                    display: block;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    /*                    &:before {
                    cursor: pointer;
                    content: "\f00e";
                    font-family: FontAwesome;
                    font-size: 2em;
                    color: $white;
                    text-align: center;
                    line-height: 200px;
                    z-index: 2;
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    transition: 200ms all ease-in;
                }
                &:after {
                    cursor: pointer;
                    content: '';
                    z-index: 1;
                    display: block;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0);
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    transition: 100ms all ease-in;
                }
                &:hover {
                    &:before {
                        opacity: 1;
                        line-height: 150px;
                    }
                    &:after {
                        background-color: rgba(0, 0, 0, 0.25);
                    }
                }*/
                    &:first-child {
                        margin-top: 0;
                    }
                    .categorie-title-container {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        display: block;
                        background-color: $category_header_bg;
                        padding: 10px;
                        .categorie-title {
                            margin: 0;
                            padding: 0;
                            font-size: 1.8em;
                            color: $category_header_color;
                        }
                    }
                }
                &.prod-item {
                    position: relative;
                    display: block;
                    box-shadow: 0px 2px 3px 1px $general_shadow;
                    .prod-inner {
                        display: block;
                        .prod-info {
                            padding: 20px;
                            padding-top: 25px;
                            &.has-image {
                                min-height: 95px;
                            }
                            @media screen and (max-width: 767px) {
                                padding-right: 0px;
                            }
                            display: block;
                            p {
                                margin: 0;
                            }
                            .prod-title {
                                font-size: 1.1em;
                            }
                            .prod-price-old {
                                text-align: right;
                                font-weight: normal;
                                text-decoration: line-through;
                            }
                            .prod-price {
                                text-align: right;
                                font-weight: bold;
                                white-space: nowrap;
                                @media screen and (max-width: 750px) {
                                    margin-left: -10px;
                                }
                            }
                            .prod-description {
                                font-style: italic;
                                /*font-weight: lighter;*/
                                font-size: 0.9em;
                            }
                            .order-line-divider {
                                width: 100%;
                                height: 1px;
                                margin-top: 5px;
                                margin-bottom: 5px;
                                display: block;
                                clear: both;
                                border-top: 1px dotted rgba(0, 0, 0, 0.4);
                            }
                            .prod-image-container {
                                position: inherit;
                                .prod-image-inner {
                                    height: 100%;
                                    background-color: $background_1_fallback;
                                    background-color: $background_1;
                                    background-color: $background_product;
                                    width: 20%;
                                    display: block;
                                    position: absolute;
                                    padding: 20px;
                                    top: 0;
                                    z-index: 2;
                                    max-height: 120px;
                                    .prod-img {
                                        border-radius: 2px;
                                        height: 100%;
                                        display: block;
                                        width: 100%;
                                        background-position: center;
                                        background-size: cover;
                                        opacity: 1;
                                        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
                                        transition: 100ms all ease-in;
                                        max-height: 120px;
                                        @media screen and (min-width: 992px) {
                                            height: 82px;
                                            margin-top: -15px;
                                        }
                                    }
                                }
                                @media screen and (max-width: 767px) {
                                    position: absolute !important;
                                    bottom: 7.5px;
                                    right: 0;
                                    display: block;
                                    height: 40px;
                                    width: 70px;
                                    /*height: 100px;
                                width: 150px;
                                bottom: 10px;*/
                                    .prod-image-inner {
                                        padding: 0px;
                                        left: 0;
                                        width: 100%;
                                    }
                                }
                            }
                            @media screen and (max-width: 767px) {
                                &.has-image {
                                    /*padding-bottom: 85px;*/
                                    /*min-height: 220px;*/
                                    .allergie {
                                        min-height: 95px;
                                        /*display: block;*/
                                        justify-content: flex-start;
                                        align-items: flex-start;
                                        align-content: flex-start;
                                    }
                                    .prod-image-container {
                                        @media screen and (max-width: 767px) {
                                            position: absolute !important;
                                            bottom: 7.5px;
                                            right: 0;
                                            display: block;
                                            height: 40px;
                                            width: 70px;
                                            height: 100px;
                                            width: 150px;
                                            bottom: 10px;
                                            .prod-image-inner {
                                                padding: 0px;
                                                left: 0;
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .add-inner {
                        display: block;
                        padding-right: 0px;
                        position: inherit;
                        .add-product {
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            font-weight: bolder;
                            width: 30px;
                            position: absolute;
                            height: 100%;
                            top: 0;
                            right: 0;
                            border-width: 0;
                            background-color: $green;
                            color: $white;
                            font-size: 2em;
                            transition: 100ms all ease-in;
                            display: block;
                            @media screen and (max-width: 767px) {
                                justify-content: center;
                                align-content: center;
                                font-weight: bolder;
                                width: 8.333%;
                                position: absolute;
                                text-align: center;
                                height: 100%;
                                top: 0;
                                right: 0;
                                border-width: 0;
                                background-color: $green;
                                color: #fff;
                                font-size: 2em;
                                transition: 100ms all ease-in;
                                padding: 0px;
                            }
                            &:hover {
                                background-color: $green_darken;
                            }
                        }
                    }
                    &:hover {
                        background-color: $background_1_darken;
                        .prod-inner {
                            .prod-image-container {
                                .prod-image-inner {
                                    background-color: $background_1_darken;
                                    .prod-img {
                                        &:hover {
                                            opacity: 0.75;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .order-line-divider{
            border-color: transparent !important;
        }

        //show product full image but still cover bg
        &.display_product_image_format_full{
            .prod-img{
                &.prod-img-full{
                    display: block !important;
                    visibility: visible !important;
                }
                &.prod-img-thumb{
                    display: none !important;
                    visibility: hidden !important;
                }
            }
        }
        //show product full image and contain bg
        &.display_product_image_format_full_uncropped{
            .prod-img{
                background-size: contain !important;
                background-repeat: no-repeat !important;
                box-shadow: none !important;
                &.prod-img-full{
                    display: block !important;
                    visibility: visible !important;
                }
                &.prod-img-thumb{
                    display: none !important;
                    visibility: hidden !important;
                }
            }
        }

        //show product image smaller on mobile
        &.display_product_image_position_small{
            @media screen and (max-width: 767px){
                .prod-info.has-image .allergie{
                    min-height: inherit !important;
                }
                .prod-image-container {
                    right: 11px !important;
                    height: 40px !important;
                    width: 70px !important;
                    bottom: inherit !important;
                    top: 50px !important;
                }
                .prod-img{
                    background-position: right !important;
                }
            }
        }
    }
}

.add-prod-container {
    background-color: $background_1_fallback;
    background-color: $background_1;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    padding-top: 0px;
    color: $text_default;
    .form-group {
        margin: 0;
        display: flex;
    }
    .product-total-price-width-addon {
        font-weight: bold;
    }
    .count-prod-icon {
        font-size: 1.5em;
        line-height: 34px;
        display: block;
        padding-right: 10px;
    }
    .prod-count {
        max-width: 80px;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        opacity: 1;
    }
}

.toppings {
    margin-top: -10px;
    .toppings-inner {
        padding: 10px;
        display: block;
        background-color: $background_2_fallback;
        background-color: $background_2;
        border: 15px solid $background_1_fallback;
        border: 15px solid $background_1;
        .topping-flex {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;
            .Addon {
                flex-basis: 100%;
                @media screen and (min-width: 700px) {
                    flex-basis: 50%;
                }
                @media screen and (min-width: 1000px) {
                    flex-basis: 33%;
                }
                @media screen and (min-width: 1200px) {
                    flex-basis: 25%;
                }
            }
            .Addon .form-group {
                display: block;
                max-width: 95%;
                margin: 0 auto;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .topping {
            color: $text_default;
            padding: 5px;
            margin-top: 2.5px;
            margin-bottom: 2.5px;
            display: block;
            /*background-color: $background_1_fallback;*/
            /*background-color: $background_1;*/
            position: relative;
            border-radius: 2px;
            flex-basis: 47%;
            width: 47%;
            /*border-left: 2.5px solid $background_2;*/
            /*border-right: 2.5px solid $background_2;*/
            /*.form-group {*/
            /*margin: 0;*/
            .checkbox {
                display: inline-block;
                height: 100%;
                padding-left: 5px;
                position: relative;
                font-family: "Noto Sans", sans-serif;
                font-weight: normal;
                width: 100%;
                margin-bottom: 0;
                margin-top: 0;
                label {
                    display: block;
                    height: 100%;
                    background-color: #f5f5f6;
                    padding-top: 7.5px;
                    padding-bottom: 7.5px;
                    padding-left: 35px;
                    &:before {
                        margin-left: 10px;
                    }
                    &:after {
                        top: 10px;
                        left: 30px;
                        height: 17px;
                        width: 17px;
                    }
                    &:hover {
                        background-color: $background_1_darken;
                    }
                }
            }
            /*}*/
            @media screen and (max-width: 600px) {
                flex-basis: 100%;
                width: 100%;
            }
            @media screen and (min-width: 992px) {
                flex-basis: 32%;
                width: 32%;
            }
            @media screen and (min-width: 1600px) {
                flex-basis: 24%;
                width: 24%;
            }
            .form-group {
                /*margin: 0;*/
                .checkbox {
                    /*position: inherit;*/
                    /*margin: 0;*/
                    /*padding: 0;*/
                }
            }
            label {
                &:after {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    display: block;
                }
            }
        }
    }
    .select-topping {
        .topping {
            flex-basis: 49%;
            @media screen and (max-width: 991px) {
                flex-basis: 100%;
                width: 100%;
            }
            label {
                &:after {
                    display: none;
                }
            }
        }
    }
    .topping-container {
        &:nth-child(2) {
            .toppings-inner {
                border-top-width: 0px;
            }
        }
    }
}

#bestel_modal {
    .modal-content {
        background-color: $background_2;
        .toppings {
            .add-prod-container {
                background-color: $background_2;
            }
            .toppings-inner {
                border-color: $background_2;
                .topping-flex {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .topping {
                        padding: 0px 5px;
                        display: flex;
                        padding: 0px 5px;
                        .form-group {
                            height: 100%;
                            display: block;
                            .checkbox {
                                display: block;
                                height: 100%;
                                margin: 0;
                            }
                        }
                    }
                }
            }
            .form-group {
                margin: 0;
                width: 100%;
                display: block;
            }
        }
    }
}

.menu-header-text {
    @media screen and (min-width: 1300px) {
        max-width: 685px;
        margin-left: 15px !important;
    }
}

@media screen and (min-width: 1200px) {
    .order-list-column {
        width: 80%;
    }
    .desktop-categories {
        .desktop-categories-column {
            width: 20%;
        }
    }
}

.pay-choise {
    .chosen-succesfully {
        display: none;
    }
    transition: 200ms all ease-in;
    &.succes-choise {
        background-color: #5cb85c;
        .chosen-succesfully {
            display: block;
            color: white;
            position: absolute;
            right: 20px;
            top: 2.5px;
            font-size: 2em;
        }
    }
    .button-payname {
        font-size: 14px;
        @media screen and (max-width: 670px) {
            font-size: 12px;
        }
        @media screen and (max-width: 400px) {
            font-size: 10px;
        }
    }
}

/*$black_accent_1*/

/*body.inverse {
    .order-wrapper {
        .store-info {
            background-color: $black_accent_1;
            .store-inner {
                .info-rating {
                    background-color: $black_accent_2;
                    .rating-link {
                        color: $text_default_inverse;
                        &:active,
                        &:focus,
                        &:link,
                        &:hover {
                            color: $text_default_inverse;
                        }
                    }
                }
                .info-list {
                    p,
                    span {
                        color: $text_default_inverse;
                    }
                    .info-title {
                        color: $text_default_inverse;
                    }
                }
            }
        }
        .list-layout {
            .nav-categories {
                .list-layout-title {
                    color: $white;
                }
            }
            .toppings {
                .toppings-inner {
                    background-color: $black_accent_2;
                    border: 15px solid $black_accent_2_lighten;
                    .topping {
                        color: $text_default_inverse;
                        background-color: $black_accent_2_lighten;
                        &:hover {
                            background-color: $black_accent_2_darken;
                        }
                    }
                }
            }
            .product-list {
                .add-prod-container {
                    background-color: $black_accent_2_lighten;
                    color: $text_default_inverse;
                }
                .list-item {
                    background-color: $black_accent_2_lighten;
                    &:hover {
                        background-color: $black_accent_1;
                    }
                    &.prod-item {
                        .prod-inner {
                            .prod-info {
                                p {
                                    color: $white;
                                }
                                .order-line-divider {
                                    border-color: $white;
                                }
                                .prod-image-container {
                                    .prod-image-inner {
                                        background-color: $black_accent_2_lighten;
                                    }
                                }
                            }
                        }
                        &:hover {
                            .prod-inner {
                                .prod-info {
                                    .prod-image-container {
                                        .prod-image-inner {
                                            background-color: $black_accent_1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .block-layout {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $white;
            }
            .categorie-item {
                background-color: $black_accent_1;
                .categorie-title {
                    color: $white;
                }
            }
            .product-info {
                background-color: $black_accent_1;
                color: $white;
            }
        }
    }
}
*/
/*.colorscheme{
	white : $white;
	black : $black;
	lightgrey : $lightgrey;
	darkgrey : $darkgrey;
	grey : $grey;
	text_default : $text_default;
	text_default_inverse : $text_default_inverse;
	black_accent_1 : $black_accent_1;
	black_accent_2 : $black_accent_2;
	black_accent_2_darken : $black_accent_2_darken;
	black_accent_2_lighten : $black_accent_2_lighten;
	background_1_fallback : $background_1_fallback;
	background_1 : $background_1;
	background_1_darken : $background_1_darken;
	background_2_fallback : $background_2_fallback;
	background_2 : $background_2;
	background_3_fallback : $background_3_fallback;
	background_3 : $background_3;
	accent_1_fallback : $accent_1_fallback;
	accent_1 : $accent_1;
	accent_1_darken_fallback : $accent_1_darken_fallback;
	accent_1_darken : $accent_1_darken;
	accent_1_color : $accent_1_color;
	green : $green;
	green_darken : $green_darken;
	red : $red;
	red_darken : $red_darken;
	orange : $orange;
}*/
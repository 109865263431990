.btn-primary {
    background-color: $accent_1_fallback;
    background-color: $accent_1;
    border-color: $accent_1_darken_fallback;
    border-color: $accent_1_darken;
    &.active,
    &:hover,
    &:active,
    &:focus {
        background-color: $accent_1_darken_fallback;
        background-color: $accent_1_darken;
    }
}

.btn {
    border-radius: 2px;
    /*box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.2);*/
}

.pay-methods {
    list-style-type: none;
    display: block;
    padding: 0;
    margin: 0;
    &.pay-horizontal {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
            max-width: 30px;
        }
    }
}

.btn,
button {
    /*border-width: 0px !important;*/
    border-color: #333;
}

.postcode-row {
    @media screen and (min-width: 1001px) {
        transition: 150ms all ease-in;
        transform: scale(1);
        &:hover {
            /*transform: scale(1.3);*/
        }
    }
    .post-code-nav {
        >a {
            display: block;
            float: left;
            font-weight: bold;
            border-radius: 0px;
            border-width: 0px;
            font-size: 1.4em;
            width: 100%;
            padding-top: 7.5px;
            padding-bottom: 7.5px;
            border-top-width: 0px;
            &:first-child {
                filter: brightness(75%);
            }
            &:nth-child(2) {
                margin-bottom: 20px;
            }
            @media screen and (min-width: 767px) {
                margin-bottom: 20px;
                width: 50%;
                &:first-child {
                    /*border-top-left-radius: 4px;*/
                }
                &:nth-child(2) {
                    /*border-top-right-radius: 4px;*/
                }
            }
        }
    }
    .postcode-container {
        margin-top: 3em;
        margin-bottom: 3em;
        border-radius: 4px;
        box-shadow: 0px 4px 3px 1px rgba(0, 0, 0, 0.3);
        .postcode-header {
            background-color: $accent_1;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            &.duoHeader {
                filter: brightness(85%);
            }
            h2 {
                font-size: 1.4em;
                margin: 0;
                padding: 0;
                padding-bottom: 15px;
                font-weight: bolder;
                text-align: center;
                display: block;
                padding-top: 15px;
                color: $accent_1_color;
            }
        }
        .postcode-body {
            display: block;
            background-color: #f5f5f5;
            padding: 20px;
            padding-top: 15px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .post_code,
            .CheckZipCode {
                float: left;
                margin: 0;
                border-radius: 4px;
                font-weight: bold;
            }
            .post_code {
                width: 70%;
                display: block;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                padding: 0px 12px;
                text-transform: uppercase;
                height: 42px;
                line-height: 42px;
            }
            .CheckZipCode {
                display: block;
                padding: 10.5px 12px;
                width: 30%;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
            @media screen and (max-width: 1015px) {
                .post_code,
                .CheckZipCode {
                    border-radius: 3px;
                    width: 100%;
                    margin-top: 2.5px;
                    margin-bottom: 2.5px;
                }
            }
        }

        @media screen and (max-width: 1001px) {
            .post_code,
            .CheckZipCode {
                width: 100%;
                /*clear: both;*/
                margin-top: 5px;
                margin-bottom: 5px;
                text-align: center;
            }
        }
    }
}

.skip-spacer-6 {
    margin-top: -60px;
}

.spacer-dotted {
    clear: both;
    display: block;
    width: 100%;
    margin-top: 2.5px;
    margin-bottom: 5px;
    border-top: 1px dotted $lightgrey;
}

.spacer-1 {
    display: block;
    clear: both;
    height: 10px;
    float: none;
}

.spacer-1-5 {
    display: block;
    clear: both;
    height: 15px;
    float: none;
}

.spacer-2 {
    display: block;
    clear: both;
    height: 20px;
    float: none;
}

.spacer-3 {
    display: block;
    clear: both;
    height: 30px;
    float: none;
}

.show-sm {
    display: none;
}

@media screen and (max-width: 991px) {
    .show-sm {
        display: block;
    }
}

.hide-mobile {
    display: none;
    @media screen and (min-width: 767px) {
        display: block;
    }
}





/* =============
   Checkbox and Radios
============= */

.checkbox {
    padding-left: 20px;
    label {
        display: inline-block;
        padding-left: 5px;
        position: relative;
        font-family: $font-primary;
        font-weight: normal;

        &::before {
            -o-transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            background-color: $white;
            border-radius: 2px;
            border: 1px solid darken($light, 10%);
            content: "";
            display: inline-block;
            height: 17px;
            left: 0;
            margin-left: -20px;
            position: absolute;
            transition: 0.3s ease-in-out;
            width: 17px;
            outline: none !important;
            margin-top: 2px;
        }
        &::after {
            color: $muted;
            display: inline-block;
            font-size: 11px;
            height: 16px;
            left: 0;
            margin-left: -20px;
            padding-left: 3px;
            padding-top: 1px;
            position: absolute;
            top: 2px;
            width: 16px;
        }
    }
    input[type="checkbox"] {
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        outline: none !important;

        &:disabled+label {
            opacity: 0.65;
        }
    }
    input[type="checkbox"]:focus+label {
        &::before {
            outline-offset: -2px;
            outline: none;
        }
    }
    input[type="checkbox"]:checked+label {
        &::after {
            content: "\F12C";
            font-family: 'Material Design Icons';
            font-weight: bold;
        }
    }
    input[type="checkbox"]:disabled+label {
        &::before {
            background-color: $light;
            cursor: not-allowed;
        }
    }
}

.checkbox.checkbox-circle {
    label {
        &::before {
            border-radius: 50%;
        }
    }
}

.checkbox.checkbox-inline {
    margin-top: 0;
}

.checkbox.checkbox-single {
    label {
        height: 17px;
    }
}

.checkbox-custom {
    input[type="checkbox"]:checked+label {
        &::before {
            background-color: $custom;
            border-color: $custom;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-primary {
    input[type="checkbox"]:checked+label {
        &::before {
            background-color: $primary;
            border-color: $primary;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-danger {
    input[type="checkbox"]:checked+label {
        &::before {
            background-color: $danger;
            border-color: $danger;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-info {
    input[type="checkbox"]:checked+label {
        &::before {
            background-color: $info;
            border-color: $info;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-warning {
    input[type="checkbox"]:checked+label {
        &::before {
            background-color: $warning;
            border-color: $warning;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-success {
    input[type="checkbox"]:checked+label {
        &::before {
            background-color: $success;
            border-color: $success;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-dark {
    input[type="checkbox"]:checked+label {
        &::before {
            background-color: $dark;
            border-color: $dark;
        }
        &::after {
            color: $white;
        }
    }
}






/* Radios */

.radio {
    padding-left: 20px;
    label {
        display: inline-block;
        padding-left: 5px;
        position: relative;
        font-family: $font-primary;
        font-weight: normal;

        &::before {
            -o-transition: border 0.5s ease-in-out;
            -webkit-transition: border 0.5s ease-in-out;
            background-color: $white;
            border-radius: 50%;
            border: 1px solid darken($light, 10%);
            content: "";
            display: inline-block;
            height: 17px;
            left: 0;
            margin-left: -20px;
            outline: none !important;
            position: absolute;
            transition: border 0.5s ease-in-out;
            width: 17px;
        }
        &::after {
            -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -webkit-transform: scale(0, 0);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            background-color: $muted;
            border-radius: 50%;
            content: " ";
            display: inline-block;
            height: 11px;
            left: 3px;
            margin-left: -20px;
            position: absolute;
            top: 3px;
            transform: scale(0, 0);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            width: 11px;
        }
    }
    input[type="radio"] {
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        outline: none !important;
        &:disabled+label {
            opacity: 0.65;
        }
    }
    input[type="radio"]:focus+label {
        &::before {
            outline-offset: -2px;
        }
    }
    input[type="radio"]:checked+label {
        &::after {
            -ms-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
    }
    input[type="radio"]:disabled+label {
        &::before {
            cursor: not-allowed;
        }
    }
}

.radio.radio-inline {
    margin-top: 0;
}

.radio.radio-single {
    label {
        height: 17px;
    }
}


.radio-custom {
    input[type="radio"]+label {
        &::after {
            background-color: $custom;
        }
    }
    input[type="radio"]:checked+label {
        &::before {
            border-color: $custom;
        }
        &::after {
            background-color: $custom;
        }
    }
}

.radio-primary {
    input[type="radio"]+label {
        &::after {
            background-color: $primary;
        }
    }
    input[type="radio"]:checked+label {
        &::before {
            border-color: $primary;
        }
        &::after {
            background-color: $primary;
        }
    }
}

.radio-danger {
    input[type="radio"]+label {
        &::after {
            background-color: $danger;
        }
    }
    input[type="radio"]:checked+label {
        &::before {
            border-color: $danger;
        }
        &::after {
            background-color: $danger;
        }
    }
}

.radio-info {
    input[type="radio"]+label {
        &::after {
            background-color: $info;
        }
    }
    input[type="radio"]:checked+label {
        &::before {
            border-color: $info;
        }
        &::after {
            background-color: $info;
        }
    }
}

.radio-warning {
    input[type="radio"]+label {
        &::after {
            background-color: $warning;
        }
    }
    input[type="radio"]:checked+label {
        &::before {
            border-color: $warning;
        }
        &::after {
            background-color: $warning;
        }
    }
}

.radio-success {
    input[type="radio"]+label {
        &::after {
            background-color: $success;
        }
    }
    input[type="radio"]:checked+label {
        &::before {
            border-color: $success;
        }
        &::after {
            background-color: $success;
        }
    }
}

.radio-dark {
    input[type="radio"]+label {
        &::after {
            background-color: $dark;
        }
    }
    input[type="radio"]:checked+label {
        &::before {
            border-color: $dark;
        }
        &::after {
            background-color: $dark;
        }
    }
}

.no-padding {
    padding: 0px;
}

.store-checkout {
    flex-basis: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    .store-info {
        padding-top: 0px !important;
    }
}

.context-has-background {
    display: block;
    background-size: cover;
    background-position: center;
    &.addBackground {
        * {
            color: $imgBlokText;
        }
        &:before {
            background-color: $imgBlokBackground;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}

.colorText {
    color: $text_default;
}

.backgroundTwoColor {
    color: $background_2;
}

@media screen and (max-width: 767px) {
    .inner.content-text.relative.context-has-background {
        height: auto !important;
        min-height: 200px;
    }
}
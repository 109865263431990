﻿$circle-length: 151px;
$check-length: 36px;

@keyframes scaleAnimation {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }

    99% {
        opacity: 1;
        transform: scale(1);
    }
    100%{
        opacity: 1;
    }
}

@keyframes drawCircle {
    0% {
        stroke-dashoffset: $circle-length;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes drawCheck {
    0% {
        stroke-dashoffset: $check-length;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#successAnimationCircle {
    stroke-dasharray: $circle-length $circle-length;
    stroke: $accent_1;
}

#successAnimationCheck {
    stroke-dasharray: $check-length $check-length;
    stroke: $accent_1;
}

#successAnimationResult {
    fill: $accent_1;
    opacity: 0;
}

#successAnimation.animated {
    animation: 1.5s ease-out 0s 1 both scaleAnimation;

    #successAnimationCircle {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationCheck {
        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationResult {
        animation: 0.3s linear 0.9s both fadeIn;
    }
}

svg#successAnimation {
    position: absolute;
    right: 0;
    top: 0;
}
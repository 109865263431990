 @keyframes fa-blink {
     0% {
         opacity: 1;
     }
     50%{
        opacity: 0;
        color: limegreen;
     }
     100% {
         opacity: 1;
     }
 }

 .fa-blink {
     -webkit-animation: fa-blink .7s linear forwards;
     -moz-animation: fa-blink .7s linear forwards;
     -ms-animation: fa-blink .7s linear forwards;
     -o-animation: fa-blink .7s linear forwards;
     animation: fa-blink .7s linear forwards;
 }

 #navbar {
     .spinner {
         margin: 13px 57px;
         height: 24px;
         width: 24px;
         border: 2px solid #fff;
         border-right-color: transparent;
     }

     .nav-cart {
         border-left: 2px solid rgba(255, 255, 255, 0.3);
         color: $text_cart;
         .cart-href {
             transition: 150ms all ease-in;
             position: relative;
             width: 153px;
             padding: 0px;
             height: 50px;
             border-bottom: 1px solid rgba(21, 21, 21, 0.05);
             margin: 0;
             margin-right: -15px;
             padding-right: 15px;
             color: $text_cart; //border-width: 0px;
             //border-left: 2px solid rgba(255, 255, 255, 0.3);
             .order-info {
                 display: flex;
                 float: left;
                 width: 80px;
                 height: 100%;
                 font-size: 15px;
                 flex-wrap: wrap;
                 flex-direction: column;
                 justify-content: center;
                 padding: 0px 10px;
                 color: $white;
             }

             .order-cart {
                 display: block;
                 float: right;
                 position: relative;
                 width: 56px;
                 height: 100%;
                 line-height: 50px;
                 font-size: 2em;
                 text-align: center;
                 color: $white;
             }

             &:hover {
                 background-color: $white; //border-left: 2px solid rgba(21, 21, 21, 0.3);
                 .order-cart {
                     color: $black;
                 }

                 .order-info {
                     color: $black;
                 }
             }
         }

         &.cart-active {
             .cart-href {
                 background-color: $white; //border-left: 2px solid rgba(21, 21, 21, 0.3);
                 box-shadow: 0px -4px 2px 2px rgba(0, 0, 0, 0.5);

                 .order-cart {
                     color: $black;
                 }

                 .order-info {
                     color: $black;
                 }
             }
         }

         .cart-inner {
             border-top: 1px solid rgba(0, 0, 0, 0.25);
             height: 100%;
             max-height: 100%;
             overflow-y: auto;
             padding-bottom: 15px;

             .cart-title {
                 h2 {
                     padding: 7.5px 15px;
                 }
             }

             .error-line {
                 padding: 7.5px 15px;
                 /*background-color: $background_1_fallback;*/
                 /*background-color: $background_1;*/
                 background-color: $red;
                 display: block;

                 .error-orderprice {
                     display: block;
                     margin: 0;
                     font-size: 12px;
                     text-align: right;
                     color: $white;
                     font-weight: bolder;
                 }
             }

             .price-total-container {
                 border-bottom: 2px solid rgba(0, 0, 0, 0.3);
                 display: block;
                 padding: 15px;

                 .price-total-number-container {
                     text-align: right;
                 }

                 .price-total {
                     font-size: 16px;
                     margin: 0;
                 }
             }

             .cart-divider-strong {
                 border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
                 padding-top: 1px;
                 padding-bottom: 1px;
             }

             .cart-line {
                 position: relative;
                 padding: 7.5px 15px;
                 border-bottom: 1px dotted rgba(0, 0, 0, 0.25);

                 .spinner-line-item {
                     margin: 0;
                     height: 15px;
                     width: 15px;
                     border: 2px solid $accent_1;
                     border-right-color: transparent;
                     position: absolute;
                     right: -5px;
                     top: 5px;
                 }

                 .form-group {
                     margin-bottom: 0;
                 }

                 input[type=number]::-webkit-inner-spin-button,
                 input[type=number]::-webkit-outer-spin-button {
                     opacity: 1;
                 }

                 .prod_count {
                     max-width: 100%;
                     padding-left: 10px;
                     padding-right: 2px;
                     font-size: 11px;
                     font-weight: bolder;
                     border-radius: 2px;
                     height: 25px;
                 }

                 .prod_title,
                 .prod_price,
                 .prod_title_extra,
                 .prod_price_extra {
                     font-size: 14px;
                     line-height: 25px;
                 }

                 .prod_title_extra {
                     font-weight: lighter;
                     font-style: italic;
                     font-size: 14px;
                 }

                 .prod-title {
                     font-weight: lighter;
                 }

                 .prod_price,
                 .prod_price_extra {
                     text-align: right;
                     display: block;
                     width: 100%;
                     white-space: nowrap;
                 }

                 .prod_price {
                     font-weight: normal;
                     white-space: nowrap;
                     text-align: right;
                 }

                 .prod_delete {
                     color: #fff;
                     background-color: #d9534f;
                     border-color: #d43f3a;
                     padding: 0px 5px;
                     line-height: 23px;
                 }

                 &.extra_costs {
                     span {
                         /*font-size: 12px;*/
                     }
                 }

                 &.clean {
                     border-width: 0px;
                 }
             }

             .controls {
                 .coupon {
                     display: block;
                     padding: 15px 15px;
                     padding-top: 10px;
                     padding-bottom: 2.5px;

                     .form-group {
                         margin-bottom: 0;
                     }
                 }

                 .control {
                     .control-inner {
                         display: block;
                         padding: 15px;
                         padding-left: 0px;
                         padding-right: 0px;
                     }

                     &:first-child {
                         .control-inner {
                             padding-right: 0;
                         }
                     }

                     &:nth-child(2) {
                         .control-inner {
                             padding-left: 0;
                         }
                     }
                 }
             }
         }
     }
 }
.clear {
    clear: both;
    float: none;
    width: 100%;
    display: block;
}

.pinLockc{
    display: none;
}

.vestiging-afbeelding{
    max-width: 250px;
}

#page-wrapper.admin {
    .navbar-default {
        background-color: #353535;
        border-radius: 0;
        z-index: 99;
        border: none;
        margin-bottom: 0;
    }
    .flex-color-container {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        flex-shrink: 1;
        /*justify-content: center;*/
        /*align-items: flex-end;*/
    }
    .inner-preset {
        width: 100%;
        display: block;
        height: 190px;
        background-color: grey;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 0px;
        box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
        transform: scale(1);
        &:hover,
        &:focus {
            transform: scale(1.1);
        }
        &:active {
            transform: scale(1.05);
        }
        span {
            position: absolute;
            width: 100%;
            height: auto;
            text-align: center;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            padding-bottom: 7.5px;
            padding-top: 7.5px;
            background-color: rgba(0, 0, 0, .7);
            color: #fff;
        }
    }
    .color-form-container {
        display: block;
        padding: 10px;
        /*height: 100%;*/
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        border-right: 1px solid rgba(0, 0, 0, 0.3);
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        padding-bottom: 30px;
        position: relative;
        @media screen and (min-width: 799px) {
            width: 50%;
            flex-basis: 50%;
        }
        @media screen and (min-width: 1100px) {
            width: 33%;
            flex-basis: 33%;
        }
        .color-form-inner {
            display: block;
            padding: 10px;
            /*background-color: red;*/
            .sp-replacer.sp-light {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }
    }
}


body.dragging,
body.dragging * {
    cursor: move !important;
}

.dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
}


#view_container {
    border: 2px solid #666;
    background-color: #eee;
    div[preview-col] {
        display: block;
        padding: 15px;
        .inner {
            height: 40px;
            background-color: #666;
            &.edit-preview-col {
                cursor: pointer;
                &:hover {
                    background-color: #444;
                }
            }
            .preview-oid {
                display: block;
                top: 4px;
                left: 4px;
                position: absolute;
                background-color: #333;
                color: #fff;
                border-radius: 100%;
                height: 25px;
                width: 25px;
                line-height: 25px;
                text-align: center;
                font-weight: bolder;
            }
        }
    }
}

#device_container {
    .col-spread {
        display: none;
    }
    &.xs {
        .col-spread-xs {
            display: block;
        }
    }
    &.sm {
        .col-spread-sm {
            display: block;
        }
    }
    &.md {
        .col-spread-md {
            display: block;
        }
    }
    &.lg {
        .col-spread-lg {
            display: block;
        }
    }
    @media screen and (min-width: 1500px) {
        .screen {
            overflow-y: auto;
            overflow-x: hidden;
            background-color: #f5f5f5;
        }
        &.xs {
            transform: scale(0.8) translate(150px, -90px);
            #view_container div[preview-col] .inner {
                height: 140px;
            }
        }
        &.sm {
            transform: scale(0.75) translate(20px, -120px);
            #view_container div[preview-col] .inner {
                height: 140px;
            }
        }
        &.md {
            transform: scale(0.5) translate(-390px, -280px);
            #view_container div[preview-col] .inner {
                height: 120px;
            }
        }
        &.lg {
            transform: scale(0.6) translate(-330px, -190px);
            #view_container div[preview-col] .inner {
                height: 120px;
            }
        }

        &.marvel-device::after,
        &.marvel-device.iphone6:after {
            display: none!important;
        }

        #view_container {
            margin-left: 0;
            margin-right: 0;
            border-width: 0px;
            background-color: transparent;
            padding: 5px;
            div[preview-col] {
                display: block;
                padding: 5px 5px;
                .inner {
                    /*background-color: #ccc;*/
                    &.edit-preview-col {
                        &:hover {
                            /*background-color: #aaa;*/
                        }
                    }
                    .preview-oid {
                        /*background-color: #555;*/
                        /*color: #fff;*/
                    }
                }
            }
        }
    }
}

#block_list {
    li {
        display: block;
        background-color: #ddd;
        padding: 5px;
        padding-top: 7.5px;
        width: 300px;
        margin-top: 2.5px;
        margin-bottom: 2.5px;
        /*min-height: 40px;*/
        display: flex;
        .info_container {
            width: 50%;
            /*float: left;*/
            display: block;
            .block_id,
            .block_type {
                display: block;
                clear: both;
            }
        }
        .action-blocks-container {
            width: 50%;
            position: relative;
            display: block;
            padding: 5px;
            /*float: right;*/
            i {

                &.action-block-item {
                    padding: 4px;
                    color: #fff;
                    background-color: #202020;
                    border: 2px solid #202020;
                    border-radius: 3px;
                    margin-left: 2.5px;
                    margin-right: 2.5px;
                }
                &.delete {
                    cursor: pointer;
                    background-color: red;
                    border-color: red;
                }
                &.edit {
                    cursor: pointer;
                }
                &.ti-move {
                    cursor: move;
                }
            }
        }
    }
    li.placeholder {
        position: relative;
        background-color: #ccc;
        border: 2px dotted #202020;
        /** More li styles **/
    }
    li.placeholder:before {
        position: absolute;
        /** Define arrowhead **/
    }
}












///////////////////////////////////////////////////////
// EXTRA PAGINA'S
.carousel-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
    .carousel-item {
        border: 1px solid #333;
        box-shadow: 2px 0px 1px 1px rgba(0, 0, 0, 0.25);
        display: block;
        margin-bottom: 7.5px;
        margin-top: 7.5px;
        /*position: relative;*/
        .delete-item {
            position: absolute;
            right: 25px;
            margin-top: 7.5px;
            color: white;
            cursor: pointer;
            background-color: red;
            z-index: 1;
            display: block;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            border-radius: 3px;
            cursor: pointer;
            transition: 100ms all ease-in;
            border-width: 0px;
            &:hover{
                background-color: white;
                color: red;
            }
        }
        .carousel-header {
            width: 100%;
            display: block;
            background-color: #333;
            color: #fff;
            padding: 15px;
            position: relative;
            display: block;
            &.can-open {
                cursor: pointer;
            }
            i.ti-move {
                display: block;
                position: absolute;
                left: -5px;
                top: -5px;
                padding: 2.5px;
                background-color: #000;
                border-radius: 3px;
                cursor: move;
            }
        }
        .carousel-content {
            padding: 15px;
            display: none;
            background-color: #f5f5f5;
            .content-preview {
                padding: 15px;
                border: 1px dotted rgba(0, 0, 0, 0.4);
                background-color: #fff;
            }
        }
    }
    li.placeholder {
        position: relative;
        min-height: 50px;
        background-color: #ccc;
        border: 2px dotted #202020;
        /** More li styles **/
    }
    li.placeholder:before {
        position: absolute;
        /** Define arrowhead **/
    }
}
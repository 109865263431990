@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');
/*font-family: 'Lora', serif;*/

@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900');
/*font-family: 'Playfair Display', serif;*/

@import url('https://fonts.googleapis.com/css?family=Lora:400,700');
/*font-family: 'Roboto', sans-serif;*/

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,700,900');
/*font-family: 'Rubik', sans-serif;*/

$font-default: 'Roboto', sans-serif;
$white : #ffffff;
$black : #000000;
$lightgrey : #cccccc;
$darkgrey : #333333;
$grey : #9a9a9a;

$wrapper_shadow: rgba(0,0,0,0.49);
$general_shadow: rgba(0,0,0,0.24);
$background_logo: rgba(1,1,1,0);

$text_default : #333334;
$text_default_inverse : #fffffe;

$text_cart: #1a1a1a;

$black_accent_1 : #000001;
$black_accent_2 : #1a1a1b;
$black_accent_2_darken : darken($black_accent_2,5%);
$black_accent_2_lighten : lighten($black_accent_2,5%);

$background_1_fallback : #f5f5f5;
$background_1 : #f5f5f6;
$background_1_darken : darken($background_1,5%);
$choise_background_1_color : #fffff3;

$background_2_fallback : #fffff5;
$background_2 : #fffff5;

$background_3 : #fffff7;

$nav_background : rgba(21,21,21,0.75);

$background_product : #fffff2;

$category_header_bg: rgba(0,0,0,0.51);
$category_header_color: #fffff1;


$accent_1_fallback : #337ab7;
$accent_1 : #337ab7; //accent kleur voor meeste buttons, decoratie en omlijning
/*$accent_1_fallback : #db3832;
$accent_1 : #db3832;*/
$accent_1_darken_fallback : darken($accent_1_fallback,10%);
$accent_1_darken : darken($accent_1,10%);
$accent_1_darken_extra : darken($accent_1,25%);
$accent_1_color : #fffff4;


$imgBlokText : #fffff9;
$imgBlokBackground : rgba(0,0,0,.4);



$green : #27ae60;
/*$green : #db3832;*/
$green_darken : darken($green,10%);
$red : #d9534f;
$red_darken : darken($red,10%);
$orange : #ffa500;

body{
	color: $text_default;
}

legend{
    color: $text_default;
}

// Custom Color
$custom:              #23b195;//19b798

$primary:             #458bc4;
$success:             #4fc55b;
$info:                #3db9dc;
$warning:             #e2ab3b;
$danger:              #d57171;
$white:               #ffffff;
$black:               #000000;
$dark:                #626773;//3f475c
$light:               #f5f5f5;
$muted:               #7a7d84;

$font-primary: 'Noto Sans', sans-serif;
$font-secondary: 'Hind', sans-serif;


$footerColor:		#222225;
$appFooterColor:	#333335;
@import 'jq-smooth.scss';
@import 'star.scss';
@import 'lightbox.scss';
@import 'font-serrat.scss';
@import 'font-awesome.scss';
@import 'dark-bottom.scss';
@import 'slick.scss';
@import 'swall.scss';
@import 'material_font.scss';
@import 'bootstrap/bootstrap.scss';
@import 'workaround/ie10viewport.scss';
@import 'fonts.scss';
@import 'color.scss';
@import 'mixins.scss';
@import 'body.scss';
@import 'compoments.scss';
@import 'content.scss';
@import 'header.scss';
@import 'cart.scss';
@import 'ripple.scss';
@import 'doneanimation.scss';
@import 'bestel.scss';
@import 'bestel-keuze.scss';
@import 'colorscheme.scss';
@import 'review.scss';
@import 'admin.scss';
//@import 'sushipoint.scss';

@import 'fullwidth.scss';

/*/////////////////////////*/

.col-center {
    display: block;
    margin: 0 auto;
    float: none;
}

.show-xs,
.show-sm,
.show-md,
.show-lg,
{
    display: none !important;
}

.padding-normal{
    padding: 20px;
}

.hide-xs,
.hide-md,
.hide-md,
.hide-lg {
    display: block;
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: 0px 0px 5px 5px rgba(255,100,100,0.8);
    box-shadow: 0px 0px 5px 5px rgba(255,100,100,0.8);
}

.spinner {
    margin: 50px;
    height: 50px;
    width: 50px;
    animation: rotate 0.8s infinite linear;
    animation: rotate 0.8s infinite cubic-bezier(.52, .92, .9, .73);
    border: 4px solid $accent_1;
    border-right-color: transparent;
    border-radius: 50%;
}

.btn{
    border-radius: 0px;
}

.btn-success {
    background-color: $green;
    border-color: $green_darken;
    &:hover,
    &:focus {
        background-color: $green_darken;
    }
}

.btn-spinner {
    position: relative;
    cursor: not-allowed;
    &::before {
        content: '';
        z-index: 2;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        display: block;
    }
    &::after {
        content: '';
        z-index: 3;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        height: 15px;
        width: 15px;
        animation: rotate 0.8s infinite linear;
        animation: rotate 0.8s infinite cubic-bezier(0.52, 0.92, 0.9, 0.73);
        border: 3px solid #fff;
        border-right-color: transparent;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        float: none;
        padding: 5px;
        top: 25%;
        transform: translateY(-25%);
    }
    &.add-product {
        font-size: 0px !important;
        &::after {
            top: 42%;
            transform: translateY(-50%);
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 991px) {
    .hide-md {
        display: none !important;
    }
}

.menu-login-item{
    display: block;
    @media screen and (min-width: 992px){
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .show-sm{
        display: block !important;
    }
}

@media screen and (min-width: 992px) {
    .show-md {
        display: block;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .form-control {
        font-size: 16px;
    }
}

@media screen and (min-width: 1200px) {
    .hide-lg {
        display: none !important;
    }
}
body.fullscreen {
    .wrapper {
        width: 100%;
        max-width: 100%;
    }
    .container-fluid {
        width: 1465px;
        max-width: 100%;
    }
    @media screen and (min-width: 768px) {
        .header.add-slider .logo img {
            max-width: 100%;
            width: 150px;
            height: auto;
            max-height: none;
        }
        .header .navbar-default .navbar-nav>li>a {
            font-size: 16px;
        }
        #navbar .nav-cart .cart-href .order-info {
            font-size: 10px;
            flex-wrap: nowrap;
            padding: 0px 10px;
            line-height: 15px;
        }
        #navbar .nav-cart .cart-href .order-cart {
            line-height: 40px;
            font-size: 1.5em;
            text-align: left;
        }
        .header .logo,
        .header.small .logo {
            width: 1465px;
            max-height: 150px;
            margin: 0 auto;
            float: none;
            max-width: 100%;
            left: 0;
            right: 0;
        }
    }

    @media screen and (min-width: 992px) {
        .header .navbar-default.fixed-top {
            z-index: 999;
            max-width: 100%;
            width: 100%;
        }
    }
}
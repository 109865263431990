﻿//RIPPLE
.fab {
    border-radius: 50%;
    margin: 0;
    padding: 20px;
}

.material {
    position: relative;
    color: white;
    margin: 20px auto;
    height: 400px;
    width: 500px;
    background: #f45673;
}

@keyframes rippleanimation {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(100);
        opacity: 0;
    }
}

.ripple {
    overflow: hidden;
}

.ripple-effect {
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: white;
    animation: rippleanimation 2s;
}
.order-choise {
    min-height: 60vh;
    .store-list {
        display: flex;
        flex-wrap: wrap;
        &.flexbox-center{
                justify-content: center;
        }
        .store-list-conainer {
            display: block;
            flex-basis: 50%;
            padding: 15px;
            .store-info {
                background-color: $background_1_fallback;
                background-color: $background_1;
                box-shadow: 0px 2px 3px 1px $general_shadow;
                padding: 15px;
                display: block;
                .info-list {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    h3,
                    span,
                    p {
                        margin: 0;
                        color: $text_default;
                        /*background-color: $choise_background_1_color;*/
                    }
                    .info-line {
                        padding-top: 2.5px;
                        padding-bottom: 2.5px;
                    }
                }
                .store-image {
                    display: block;
                    height: 100px;
                    width: 100%;
                    background-size: cover;
                    background-position: center center;
                }
            }
            @media screen and (max-width: 991px) {
                flex-basis: 100%;
                .store-info {
                    .info-list {
                        h3 {
                            margin-bottom: 15px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}

.opentime-time:empty {
    display: block;
    &:before {
        /*content: "\f00d";*/
        /*font-family: FontAwesome;*/
        /*content: 'Gesloten';*/
        content: '\00d7';
        font-weight: bold;
    }
}

.store-option-button{
    border-radius: 0px;
}
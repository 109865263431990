body {
    background-color: $background_1_fallback;
    background-color: $background_1;
    font-family: $font-default;
    overflow-y: scroll;
    &.noscroll {
        overflow-y: hidden !important;
        overflow: hidden;
    }
}


* {
    box-sizing: border-box;
}

.no-link{
    text-decoration: none !important;
    cursor: inherit !important;
    &:hover{
        text-decoration: none;
    }
}

.switchinverse {
    display: block;
    background-color: red;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    z-index: 1000000;
}

.custom-content {
    img {
        max-width: 100%;
    }
}

.modal-content {
    background: $background_2;
    .close {
        color: $text_default;
        text-shadow: none !important;
    }
}

.wrapper {
    background-color: $background_2_fallback;
    background-color: $background_2;
    max-width: 90%;
    width: 1465px;
    margin: 0 auto;
    display: block;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 15px 1px $wrapper_shadow;
    .footer {
        background-color: $footerColor;
        p {
            text-align: center;
            margin: 0;
            padding: 10px;
            a {
                color: $white;
                cursor: pointer;
                &:hover {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        max-width: 100%;
    }
}

.container {
    max-width: 100%;
}

.relative {
    position: relative;
}

#coupon_list {
    li {
        padding: 15px;
        margin: 10px;
        background-color: $background_1;
    }
}

.app-footer{
    &.show-mobile{
        @media screen and (min-width: 991px){
            display: none !important;
        }
    }
    background-color: $appFooterColor;
    padding-top: 30px;
    padding-bottom: 25px;
    .app-footer-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        a {
            width: 200px;
            max-width: 50%;
            padding: 5px;
            img {
                display: block;
                width: 100%;
            }
        }
    }
}

body.inverse {
    background-color: $black_accent_1;
    .wrapper {
        background-color: $black_accent_2;
        .jumbotron {
            background-color: $black_accent_1;
            color: $white;
        }
        .content-text {
            color: $white;
        }
        .spacer-dotted {
            border-color: $white;
        }
    }
}

//login

.my-list{
    .my-list-item{
        border: 1px dotted rgba(30,30,30,.4);
        padding: 10px;
        background-color: $background_product;
    }
}
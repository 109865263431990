.review-list {
    display: block;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .review_item {
        display: block;
        padding: 15px;
        width: 50%;
        float: left;
        @media screen and (max-width: 1000px) {
            width: 100%;
        }
        .review_inner {
            height: 100%;
            position: relative;
            background-color: $background_3;
            display: block;
            border-radius: 2px;
            box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.15);
            padding: 10px;
            .item-date,
            span[itemprop="name"],
            span[itemprop="reviewRating"] {
                display: block;
            }
            span[itemprop="name"] {
                text-transform: capitalize;
            }
            span[itemprop="author"] {
                display: block;
                font-weight: bold;
                font-size: 20px;
                padding-bottom: 5px;
            }
        }
        .item-date {
            font-weight: bold;
            font-size: 10px;
            position: absolute;
            top: 10px;
            right: 10px;
        }
        .item-rating {
            display: none;
        }
        span.display-stars {
            color: $orange;
            font-size: 16px;
            padding-bottom: 5px;
            display: block;
        }
        span.display-stars .rating_title {
            padding-left: 10px;
            color: $text_default;
        }
        .clear {
            display: block;
            clear: both;
        }
        span.drukte {
            font-size: 10px;
            margin-top: 3px;
            margin-bottom: 3px;
            background-color: $background_2;
            padding: 3px;
        }
    }
}
.review-pagination{
    max-width: 1000px;
    @media screen and (max-width: 1199px){
        padding-left: 30px;
        padding-right: 30px;
    }
}

.review-input{
    label {
        width: 80%;
    }
    input[type="radio"], input[type="checkbox"] {
        width: 10%;
    }
}
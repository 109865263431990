.navbar-nav>li>.dropdown-menu {
    margin-left: -1px;
    margin-top: -1px;
}

.header {
    position: relative;
    &.default-header {
        .slick-slider {
            display: none;
        }
        height: 200px;
        background-color: $darkgrey;
    }
    .navbar {
        .nav-cart {
            .nav-cart-container {
                transition: 150ms all ease-in;
                height: 0;
                position: absolute;
                overflow-y: hidden;
                z-index: -1;
                width: 400px;
                width: calc(1465px * 0.3);
                display: block;
                top: 50px;
                right: -15px;
                background-color: $white;
                box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0);
                /*&.cart-active {
                box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.5);
                height: 80%;
                height: calc(100vh - 200px);
            }*/
                @media screen and (max-width: 750px) {
                    width: 100%;
                    position: fixed;
                    left: 0;
                    right: 0;
                    /*&.cart-active {
                    height: calc(100vh - 50px);
                }*/
                }
                @media screen and (max-width: 991px) {
                    z-index: 1;
                    box-shadow: none !important;
                }
            }
            &.cart-active {
                .nav-cart-container {
                    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.5);
                    height: 80%;
                    height: calc(100vh - 200px);
                    @media screen and (max-width: 750px) {
                        height: calc(100vh - 50px);
                    }
                }
            }
        }
        &.fixed-top {
            .nav-cart {
                &.cart-active {
                    .nav-cart-container {
                        height: calc(100vh - 50px);
                        @media screen and (max-width: 750px) {
                            height: calc(100vh - 50px);
                        }
                    }
                }
            }
        }
    }
    .logo {
        width: 250px;
        height: 200px;
        max-width: 250px;
        max-height: 200px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 30px;
        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            background-color: $background_logo;
            @media screen and (min-width: 992px) {
                /*padding: 10px;*/
                /*background-color: rgba(0,0,0,0.5);*/
            }
            @media screen and (max-width: 384px) {
                max-width: 100px;
            }
            @media screen and (max-width: 340px) {
                max-width: 65%;
            }
        }
    }
    &.add-slider {
        .slick-nav {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            font-size: 6em;
            font-weight: lighter;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.8);
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
            &.slick-right {
                right: 15px;
            }
            &.slick-left {
                left: 15px;
            }
        }
        .slick-slider {
            position: relative;
            display: block;
            box-sizing: border-box;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-touch-callout: none;
            -khtml-user-select: none;
            -ms-touch-action: pan-y;
            touch-action: pan-y;
            -webkit-tap-highlight-color: transparent;
            .slider-image {
                height: 500px;
                background-size: cover;
                background-position: center;
            }
        }
        .slick-list {
            position: relative;
            display: block;
            overflow: hidden;
            margin: 0;
            padding: 0;
        }
        .slick-list:focus {
            outline: none;
        }
        .slick-list.dragging {
            cursor: pointer;
            cursor: hand;
        }
        .slick-slide {
            position: relative;
            .info {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0 auto;
                display: block;
                left: 0;
                right: 0;
                text-align: center;
                .slider-heading,
                .slider-subheading {
                    color: $white;
                    text-shadow: 0px 1px 1px rgba(0, 0, 0, 25);
                }
            }
            @media screen and (max-width: 767px) {
                /*height: 100vh;*/
                height: 50vh;
                .slider-image {
                    /*height: 100vh;*/
                    height: 50vh;
                }
            }
            &.auto-image-height {
                height: inherit;
                img {
                    width: 100%;
                }
                .slider-image {
                    height: inherit;
                }
            }
        }
    }
    &.small {
        z-index: 5;
        height: 200px;
        background-size: cover;
        background-position: center;
        .logo {
            max-width: 250px;
            max-height: 150px;
        }
    }
    &.bestel {
        .cart-chevron {
            display: none;
        }
    }
    .navbar-default {
        border-radius: 0px;
        border-width: 0px;
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $nav_background;
        .closebtn,
        .mobile-menu {
            display: none;
        }
        .logo {
            display: none;
        }
        @media screen and (min-width: 992px) {
            &.fixed-top {
                position: fixed;
                top: 0;
                height: 50px;
                z-index: 999;
                max-width: 90%;
                width: 1465px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        @media screen and (max-width: 991px) {
            position: fixed;
            bottom: inherit;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
            .closebtn,
            .mobile-menu {
                display: block;
                z-index: 99;
                background: transparent;
                background-color: transparent;
                border-width: 0;
                border-radius: 0;
                font-size: 2em;
                line-height: 50px;
                padding: 0;
                margin: 0;
                width: 75px;
                text-align: center;
                color: $white;
            }
            .main-menu li {
                text-align: center;
                font-weight: lighter;
                a {
                    color: $white;
                    padding: 15px;
                }
                &.divider {
                    padding: 0px;
                    max-width: 100%;
                    width: 200px;
                    margin: 0 auto;
                    background-color: $darkgrey;
                }
            }
            .overlay {
                height: 0;
                width: 100%;
                position: fixed;
                z-index: 99;
                left: 0;
                top: 0;
                background-color: rgb(0, 0, 0);
                background-color: rgba(0, 0, 0, 0.95);
                overflow-x: hidden;
                transition: 0.5s;
                /*display: flex;*/
                justify-content: center;
                align-items: center;
                max-height: 100%;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            }
            .overlay-content {
                position: relative;
                top: 25%;
                width: 100%;
                text-align: center;
                margin-top: 30px;
            }
            .overlay .closebtn {
                position: absolute;
                top: 20px;
                right: 45px;
                font-size: 60px;
                color: $white;
                text-decoration: none;
                &:hover,
                &:focus,
                &:active {}
            }
            #navbar {
                display: block;
            }
            .navbar-header {
                height: 0;
                .logo {
                    display: block;
                    float: left;
                    position: relative;
                    padding: 5px;
                    max-height: 50px;
                    max-width: 150px;
                }
            }
            .nav-cart {
                margin-right: 5px;
            }
            .sub-menu {
                display: flex;
                margin: 0;
                padding: 0;
                justify-content: flex-end;
                border-right: 2px solid rgba(255, 255, 255, 0.3);
                padding-right: 10px;
            }
            .main-menu {
                .navbar-nav {
                    /*margin: 0;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    min-height: 100%;*/
                    margin: 0;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    min-height: 100%;
                    display: table-cell;
                    vertical-align: middle;
                    height: 100%;
                    height: 100vh;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    width: 100vw;
                    float: none;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        @media screen and (max-height: 450px) {
            .overlay a {
                font-size: 20px
            }
            .overlay .closebtn {
                font-size: 40px;
                top: 15px;
                right: 35px;
            }
        }
        .navbar-nav>li {
            >a {
                color: #fff;
                color: $white;
                background-color: transparent;
                transition: 50ms all ease-in;
                font-weight: lighter;
                font-size: 18px;
                @media screen and (max-width: 1199px) {
                    font-size: 16px;
                }
            }
            &.active,
            &:hover,
            &:focus,
            &.open {
                >a {
                    background-color: transparent;
                    color: #fff;
                    color: $white;
                }
            }
            &.active {
                >a {
                    /*font-weight: normal;*/
                }
            }
        }
        @media screen and (min-width: 992px) {
            .navbar-nav>li {
                >a {
                    padding-bottom: 10px;
                    border-bottom: 5px solid transparent;
                }
                &.active,
                &:hover,
                &:focus,
                &.open {
                    >a {
                        border-bottom: 5px solid $accent_1_fallback;
                        border-bottom: 5px solid $accent_1;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}

#navbar_mainmenu {
    .navbar-nav {
        >li {
            >a {
                color: #fff;
                color: $white;
                background-color: transparent;
                transition: 50ms all ease-in;
                font-weight: lighter;
                font-size: 18px;
                @media screen and (max-width: 1199px) {
                    font-size: 16px;
                }
                @media screen and (max-width: 991px) {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
                    width: 350px;
                    max-width: 100%;
                    margin: 0 auto;
                    max-width: 100%;
                    font-size: 18px;
                }
            }
            &.active,
            &:hover,
            &:focus,
            &.open {
                >a {
                    background-color: transparent;
                    color: #fff;
                    color: $white;
                }
            }
            &.active {
                >a {
                    /*font-weight: normal;*/
                }
            }
            @media screen and (max-width: 991px) {
                &:last-child {
                    > a {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
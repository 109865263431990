.content-container {
    padding-top: 30px;
    display: block;
    img {
        max-width: 100%;
    }
    .jumbotron {
        background-color: $background_1_fallback;
        background-color: background_1;
        border-radius: 0;
    }
    .inner {
        padding: 30px;
        margin-bottom: 15px;
        @media screen and (max-width: 1330px) {
            padding: 15px;
        }
    }
    .content-big {
        p {
            font-size: 21px;
            font-weight: lighter;
        }
    }
    .content-extrabig {
        h1 {
            font-size: 40px;
            margin-top: 0;
        }
        p {
            font-size: 25px;
            font-weight: lighter;
        }
    }
    .content-medium {
        p {
            font-size: 18px;
            font-weight: lighter;
        }
    }
}

.actie-prod-container {
    &:hover,
    &:active {
        text-decoration: none !important;
    }
    .actie-product {
        box-shadow: 0px 2px 3px 1px $general_shadow;
        .cover-img {
            height: 200px;
            width: 100%;
            background-size: cover;
            background-position: center;
            display: block;
            position: relative;
            &:before {
                content: "\f07a\00a0\f055";
                font-family: FontAwesome;
                font-size: 4em;
                color: $white;
                text-align: center;
                line-height: 300px;
                z-index: 2;
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                opacity: 0;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                transition: 200ms all ease-in;
            }
            &:after {
                content: '';
                z-index: 1;
                display: block;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0);
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                transition: 100ms all ease-in;
            }
            .actie-product-title {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.75);
                color: $white;
                margin: 0;
                padding: 7.5px 10px;
                font-size: 1.25;
                font-weight: lighter;
            }
        }
        .actie-product-cta {
            display: block;
            background-color: $green;
            background: linear-gradient(to left, $green 50%, $green_darken 50%);
            background-size: 200% 100%;
            background-position: right bottom;
            color: $white;
            position: relative;
            cursor: pointer;
            transition: 250ms all ease-in;
            .price {
                margin: 0;
                padding: 0;
                padding: 10px;
                width: 80%;
                display: block;
                min-height: 39px;
                .price_old {
                    text-decoration: line-through;
                }
                .price_word {
                    font-size: 10px;
                    padding-left: 5px;
                    padding-right: 5px;
                    &+.price_new {
                        font-weight: bolder;
                    }
                }
            }
            .add {
                font-weight: bolder;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 20%;
                text-align: center;
                font-size: 2em;
                background-color: rgba(0, 0, 0, 0.15);
                line-height: 39px;
            }
        }
    }
    &:hover,
    &:focus {
        .actie-product-cta {
            background-position: left bottom;
        }
        .cover-img {
            &:after {
                background-color: rgba(0, 0, 0, 0.5);
            }
            &:before {
                opacity: 1;
                line-height: 200px;
            }
        }
        a,
        span {
            text-decoration: none;
        }
    }
}

.price{
    min-height: 39px;
}